import React from 'react'
import { connect } from 'react-redux';
import cartIcon from '../../assets/images/cartIcon.svg';

const CartIcon = props => {
  const {handleCartClick, cart} = props;

  return (
    <div className="CartIcon" onClick={handleCartClick}>
      <img className="cart" src={cartIcon} alt="cart icon" />
      { cart.items.length > 0 && <span className="itemsCount">{cart.items.length}</span>}
    </div>
    
  );
}

const mapStateToProps = state => ({
  cart: state.orderFlow.activeFlow.cart
});

export default connect(mapStateToProps)(CartIcon);