import store from "../redux/store";

const api = (() => {
  const apiDomain = process.env.REACT_APP_API_SERVER;

  const fetchCategories = async (merchantUser) => {
    const response = await fetch(`${apiDomain}/menu/categories/${merchantUser}`);
    const data = await response.json();
    return data;
  }

  const fetchPresentations = async (merchantUser) => {
    const response = await fetch(`${apiDomain}/menu/presentations/${merchantUser}`);
    const data = await response.json();
    return data;
  }

  const fetchMerchantInfo = async (merchantUser) => {
    const response = await fetch(`${apiDomain}/menu/merchant/${merchantUser}`);
    const data = await response.json();
    return data;
  }

  const submitOrder = async () => {
    const state = store.getState();
    const {activeFlow} = state.orderFlow;
    const {user: merchantUser} = state.merchant;
    const {checkout, cart} = activeFlow;

    const orderInfo = {
      orderType: checkout.orderType.id,
      customerEmail: checkout.email,
      customerName: checkout.name,
      comment: checkout.comment ? checkout.comment : '',
      customerPhone: checkout.phoneNumber,
      customerAddress: checkout.address,
      deliveryFee: cart.deliveryFee,
    };

    const items = cart.items.map(item => 
    ({
      presId: item.id_product_presentation, 
      quantity: item.quantity, 
      price: parseFloat(item.precio),
      comment: item.comment ? item.comment : '',
    }));

    const response = await fetch(`${apiDomain}/menu/processOrder/${merchantUser}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderInfo,
        items,
      })
    });

    const data = await response.json();
    return data;
  }

  const getPostOrderDetails = async (merchantUser, orderId) => {
    const response = await fetch(`${apiDomain}/menu/postOrderInfo/${merchantUser}/${orderId}`);
    const data = await response.json();
    const res = {};
    let nroPedido = '';

    switch(data.id_tipo_pedido) {
      case 2 : {
        nroPedido += data.order_take_away.nro_pedido;
        break;
      }
      case 3 : {
        nroPedido += data.order_delivery.nro_pedido;
        break;
      }
    }

    res.presentations = data.presentations.map(pres => {
      pres.price = pres.order_detail.precio;
      pres.quantity = pres.order_detail.cant;
      return pres;
    });

    res.subTotal = parseFloat(res.presentations.reduce((acc, nextItem) => acc + nextItem.price * nextItem.quantity, 0.00));
    res.nroPedido = nroPedido;
    res.customerEmail = data.customerEmail;
    res.deliveryFee = parseFloat(data.amountCostShipping);
    res.orderTypeId = data.id_tipo_pedido;

    return res;
  }

  return {
    fetchCategories,
    fetchPresentations,
    fetchMerchantInfo,
    submitOrder,
    getPostOrderDetails
  };

})();

export default api;