import styles from './Footer.module.scss';
import logo from '../../../assets/images/logo.svg';
import fbIcon from '../../../assets/images/facebook_icon.svg';
import igIcon from '../../../assets/images/instagram_icon.svg';
import youtubeIcon from '../../../assets/images/youtube_icon.svg';
import whatsappIcon from '../../../assets/images/whatsapp_icon.svg';

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.contentWrapper}>
        <div className={styles.actualFooter}>
          <div className={styles.sign}>
            <a href="/"><img className={styles.logo} src={logo} alt="Gal-Da software para restaurantes logo" /></a>
            <h4>Centros gastronómicos</h4>
            <div className={styles.searchWrapper}>
              <span>Suscríbete a nuestro newsletter</span>
              <div className={styles.searchBox}>
                <input type="text" />
                <span className={styles.searchIcon}>

                </span>
              </div>
            </div>
            <div className={styles.socialIcons}>
              <a target="_blank" href="http://www.facebook.com/socialgalda/"> <img src={fbIcon} alt="Galda icono pequeño de facebook" /></a>
              <a target="_blank" href="http://www.instagram.com/galda_oficial"><img src={igIcon} alt="Galda icono pequeño de instagram" /></a>
              <a target="_blank" href="http://www.youtube.com/channel/UCUSCEbKuiOIUsD35ZqE1uBg"><img src={youtubeIcon} alt="Galda icono pequeño de youtube" /></a>
              <a target="_blank" href="https://wa.me/51954864752?text=%C2%A1Hola!%20Te%20interesa%20Gal-Da,%20escr%C3%ADbenos%20y%20te%20guiaremos!%20%F0%9F%99%8C"><img src={whatsappIcon} alt="Galda icono pequeño de whatsapp" /></a>
            </div>
          </div>
          <div className={styles.columnFooter}>
            <h4>AYUDA AL CLIENTE</h4>
            <ul>
              <li> <a href="http://blog.gal-da.com/" target="_blank">Centro de ayuda</a></li>
              <li> <a href="http://blog.gal-da.com/2021/04/01/menu-carta-digital/" target="_blank">Preguntas frecuentes</a></li>
              <li> <a href="https://www.gal-da.com/general-terms-of-use" target="_blank">Términos y condiciones</a></li>
              <li> <a href="https://www.gal-da.com/privacy-policy" target="_blank">Política de privacidad</a></li>
            </ul>
          </div>
          <div className={styles.columnFooter}>
            <h4>TE OFRECEMOS</h4>
            <ul>
              <li> <a >Servicio al cliente 24/7</a></li>
              <li> <a >Servicio global y seguro</a></li>
              <li> <a >Actualizaciones diarias</a></li>
            </ul>
          </div>
          <div className={styles.columnFooter}>
            <h4>CONTACTO</h4>
            <ul>
              <li> <a >Siéntase libre de contactarnos</a></li>
              <li> <a >ventas@gal-da.com</a></li>
              <li> <img className="socialIcon" src={whatsappIcon} alt="Galda icono pequeño de whatsapp" /> <a target="_blank"href="https://wa.me/51954864752?text=%C2%A1Hola!%20Te%20interesa%20Gal-Da,%20escr%C3%ADbenos%20y%20te%20guiaremos!%20%F0%9F%99%8C"> &nbsp; (+51) 954 864 752 </a> </li>
              <li> <a href="https://www.tidio.com/talk/zny7ywoh2yvjkjxwtarzxy7qyp9njaxt" target="_blank"> Chat en vivo! </a></li>
            </ul>
          </div>
        </div>
        
        <div className={styles.closingFooter}>
          <hr></hr>
          <span className={styles.copyright}>
            &copy; {new Date().getFullYear()} Copyright Gal-Da
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;