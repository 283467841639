import React, { useEffect } from 'react';
import Header from './checkout/Header';
import '../assets/styles/checkout.scss';
import Info from './checkout/Info';
import CheckoutCart from './checkout/CheckoutCart';
import { connect } from 'react-redux';
import { createNewOrderFlow, loadMerchantInfo } from '../redux/actions';
import api from '../services/api';
import { useParams } from 'react-router-dom';

const Checkout = props => {
  const {loadMerchantInfo, createNewOrderFlow, merchant} = props;
  const params = useParams();
  const {merchantUser} = params;

  useEffect(() => {    
    api.fetchMerchantInfo(merchantUser).then((merchantInfo) => {
      loadMerchantInfo(merchantInfo);
      createNewOrderFlow(merchantInfo);
    });
  }, []);

  return (
    <div className="Checkout">
      <div className="contentWrapper">
        <Header merchant={merchant} />
        <div className="infoCartWrapper">
          <Info merchant={merchant} />
          <CheckoutCart merchant={merchant}/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  merchant: state.merchant,
});

const mapDispatchToProps = dispatch => ({
  loadMerchantInfo: (info) => dispatch(loadMerchantInfo(info)),
  createNewOrderFlow: (merchant) => dispatch(createNewOrderFlow(merchant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
