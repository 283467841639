import React from 'react'
import closeIcon from '../../assets/images/closeIcon.svg';

const RightTopCloseButton = props => {
  const {handleClose} = props;

  return (
    <button className="RightTopCloseButton" onClick={handleClose}>
      <img src={closeIcon} alt="Close icon" />
    </button>
    
  );
}

export default RightTopCloseButton;