import React from 'react';
import merchantCoverDefImg from '../../assets/images/merchantCoverDefImg.jpg';
import randomLogo from '../../assets/images/randomLogo.png';

const Header = ({merchant}) => {
  return (
    <div className="Header">
      <div className="fillBackgroundImg">
        <img src={merchantCoverDefImg} alt="Gal-da merchant cover image on checkout" />
        <div className="overlay"></div>
      </div>
      <div className="logoWrapper">
        <img src={merchant.signedLogoUrl && merchant.signedLogoUrl} alt="Gal-da merchant logo" />
      </div>
    </div>
  );
};

export default Header;