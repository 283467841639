import { LOAD_MERCHANT_INFO } from "../actions";

const initialState = {
  user: '',
  moneda: '$',
}

const merchantReducer = (state = initialState, action) => {
  switch(action.type) {
    case LOAD_MERCHANT_INFO: {
      return Object.assign({}, state, {user: action.info.empresa_usuario, ...action.info})
    }
    default: return state;
  }
}

export default merchantReducer;
