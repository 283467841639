import React from 'react'

const Backdrop = props => {
  const {children} = props;
  return (
    <div className="Backdrop">
      {children}
    </div>
  );
}

export default Backdrop;
