export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const ADD_ITEM_CART = 'ADD_PRODUCT_CART';
export const REMOVE_ITEM_CART = 'REMOVE_PRODUCT_CART';
export const LOW_ITEM_QUANTITY_CART = 'LOW_ITEM_QUANTITY_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const FILTER_PRESENTATIONS_NAME = 'FILTER_PRESENTATIONS_NAME';
export const FILTER_PRESENTATIONS_CATG = 'FILTER_PRESENTATIONS_CATG';
export const LOAD_MERCHANT_INFO = 'LOAD_MERCHANT_INFO';
export const CHANGE_CHECKOUT_ORDER_TYPE = 'CHANGE_CHECKOUT_ORDER_TYPE';
export const ADD_INFO_TO_CHECKOUT = 'ADD_INFO_TO_CHECKOUT';
export const CREATE_NEW_ORDER_FLOW = 'CREATE_NEW_ORDER_FLOW';
export const ADD_DELIVERY_FEE = 'ADD_DELIVERY_FEE';
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';

export const toggleModal = (show, modal = null) => {
  return {
    type: TOGGLE_MODAL,
    show,
    modal,
  }
};

export const addItemCart = (item) => {
  return {
    type: ADD_ITEM_CART,
    item,
  }
}

export const removeItemCart = (itemId) => {
  return {
    type: REMOVE_ITEM_CART,
    itemId,
  }
}

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  }
}

export const lowItemQuantityCart = (item) => {
  return {
    type: LOW_ITEM_QUANTITY_CART,
    item
  }
}

export const filterPresentationsName = (filter) => {
  return {
    type: FILTER_PRESENTATIONS_NAME,
    filter,
  }
}

export const filterPresentationsCatg = (filter) => {
  return {
    type: FILTER_PRESENTATIONS_CATG,
    filter,
  }
}

export const loadMerchantInfo = (info) => {
  return {
    type: LOAD_MERCHANT_INFO,
    info,
  }
}

export const changeCheckoutOrderType = (orderType) => {
  return {
    type: CHANGE_CHECKOUT_ORDER_TYPE,
    orderType
  }
}

export const addInfoToCheckout = (info) => {
  return {
    type: ADD_INFO_TO_CHECKOUT,
    info,
  }
}

export const createNewOrderFlow = (merchant) => {
  return {
    type: CREATE_NEW_ORDER_FLOW,
    merchant,
  }
}

export const addDeliveryFee = (deliveryFee) => {
  return {
    type: ADD_DELIVERY_FEE,
    deliveryFee,
  }
}

export const setCategoriesList = (categories) => {
  return {
    type: SET_CATEGORIES_LIST,
    categories
  }
}