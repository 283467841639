import React, { useState } from 'react';
import styles from './AsyncButton.module.scss';

const AsyncButton = (props) => {
  const {title, onClick, className} = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <button className={`${styles.AsyncButton} ${className}`} onClick={handleOnClick}>
      {
        isLoading 
        ? 
        <div className={styles.loader}></div>
        :
        title
      }
    </button>
  )
};

export default AsyncButton;
