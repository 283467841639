import {createStore} from 'redux';
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['modal']
 };

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer, {
  modal: {
    element: null,
    show: false,
  },
  // cart: {
  //   items: [],
  //   totalAmount: 0.00
  // },
  presentations: {
    filterName: '',
    filterCatgId: null,
  },
});

export const persistor = persistStore(store);

export default store;
