import React from 'react';
import { connect } from 'react-redux';
import './assets/styles/app.scss';
import RestaurantPage from './components/RestaurantPage';
import {
  Route,
  BrowserRouter as Router, 
  Routes} from 'react-router-dom';
import Checkout from './components/Checkout';
import { createNewOrderFlow } from './redux/actions';
import PostOrder from './components/post-order/PostOrder';
import Landing from './pages/landing';

const App = props => {

  const {modal} = props;

  return (
    <div className="App" >
      <Router>
        {modal.show ? modal.element : <></>}
        <Routes >
          <Route path="/:merchantUser" element={<RestaurantPage />} />
          <Route path="/:merchantUser/checkout" element={<Checkout />} />
          <Route path="/:merchantUser/order/:orderId" element={<PostOrder />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </Router>
    </div>
  );
}

const mapStateToProps = state => ({
  modal: state.modal,
});

const mapDispatchToProps = dispatch => ({
  createNewOrderFlow: (merchant) => dispatch(createNewOrderFlow(merchant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
