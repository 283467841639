import React from 'react';
import styles from './ProductListItem.module.scss';

const ProductListItem = (props) => {

  const {currency, cartItem} = props;

  return (
    <div className={styles.ProductListItem}>
      <div className={styles.imgWrapper}>
        {(cartItem.signedImageurl && cartItem.signedImageurl !== 'not found' )? <img className={styles.img} src={cartItem.signedImageurl} /> : <div className={styles.defaultImg}> {cartItem.product.name[0]} </div>}
      </div>
      <img />
      <div className={styles.productInfo}>
        <span className={styles.name}>{`${cartItem.product.name}`}</span>
        <span className={styles.name}>{`${cartItem.presentacion}`}</span>
        <span className={styles.quantity}>{`Cantidad: ${cartItem.quantity}`}</span>
      </div>
      <div className={styles.pricing}>
        <span className={styles.price} >{currency}{cartItem.price}</span>
      </div>
    </div>
  );
};

ProductListItem.defaultProps = {
  currency: '$',
};

export default ProductListItem;
