import { ADD_ITEM_CART, CLEAR_CART, LOW_ITEM_QUANTITY_CART, REMOVE_ITEM_CART, ADD_DELIVERY_FEE }from '../actions';

const initialState = {
  items: [],
  deliveryFee: 0.00,
  totalAmount: 0.00,
}

const calculateTotalAmount = (items) => {
  return items.reduce((acc, nextItem) => acc + nextItem.quantity * nextItem.precio, 0);
}

const cartReducer = (state = initialState, action) => {
  switch(action.type) {
    case ADD_ITEM_CART: {
      const newItems = [...state.items];
      const itemFound = newItems.find( item => item.id_product_presentation === action.item.id_product_presentation);
      if(itemFound) {
        itemFound.quantity += action.item.quantity;
        itemFound.price = itemFound.quantity * itemFound.precio;
        if(action.item.comment) itemFound.comment = action.item.comment;
      }
      else {
        action.item.price = action.item.quantity * action.item.precio; 
        newItems.push(action.item);
      }
      const totalAmount = calculateTotalAmount(newItems);
      return Object.assign({}, state, {items: newItems, totalAmount: totalAmount});
    }
    case REMOVE_ITEM_CART: {
      const newItems = state.items.filter(item => item.id_product_presentation !== action.itemId);
      const totalAmount = calculateTotalAmount(newItems);
      return Object.assign({},state, {items: newItems, totalAmount});
    }
    case LOW_ITEM_QUANTITY_CART: {
      const newItems = [...state.items];
      const itemFound = newItems.find( item => item.id_product_presentation === action.item.id_product_presentation);
      if(itemFound) {
        itemFound.quantity -= action.item.quantity;
        itemFound.price = itemFound.quantity * itemFound.precio; 
      }
      const totalAmount = calculateTotalAmount(newItems);
      return Object.assign({}, state, {items: newItems, totalAmount: totalAmount});
    }
    case ADD_DELIVERY_FEE: {
      const totalAmount = calculateTotalAmount(state.items) + action.deliveryFee;
      return Object.assign({}, state, {totalAmount: totalAmount, deliveryFee: action.deliveryFee});
    }
    case CLEAR_CART: {
      return Object.assign({},state, {items: [], totalAmount: 0.00});
    }
    default: return state;
  }
}

export default cartReducer;