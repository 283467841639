import { ADD_INFO_TO_CHECKOUT, CHANGE_CHECKOUT_ORDER_TYPE } from "../actions";

const initialState = {
  orderType: {
    id: 1,
    name: 'Delivery',
    desc: `Por el momento sólo podrás realizar el pago al momento de recibir el 
          pedido o bien según los métodos aceptados por el local. Consulta a sus
          datos de contacto para más información.`,
  },
  name: '',
  phoneNumber: '',
  email: '',
  address: '',
};

const checkoutReducer = (state = initialState, action) => {
  switch(action.type) {
    case CHANGE_CHECKOUT_ORDER_TYPE: {
      return Object.assign({}, state, {orderType: action.orderType});
    }
    case ADD_INFO_TO_CHECKOUT: {
      return Object.assign({}, state, {...action.info})
    }
    default: return state;
  }
}

export default checkoutReducer;
