import Header from "../../components/web/header"
import styles from './Landing.module.scss';
import banner from '../../assets/images/banner.png'; 
import Footer from "../../components/web/footer";
import client1Logo from "../../assets/images/landing/reldorHelados.png";
import client2Logo from "../../assets/images/landing/moviewings.png";
import client3Logo from "../../assets/images/landing/MorosYCristianos.png";
import client4Logo from "../../assets/images/landing/resypez.png";
import Carrousel from "../../components/web/carrousel";
import tabernaDesktop from '../../assets/images/landing/taberna-desktop.png';
import tabernaMobile from '../../assets/images/landing/taberna-mobile.png';
import cebicheriaDesktop from '../../assets/images/landing/cebicheria-desktop.png';
import firstSectionImg from '../../assets/images/landing/first-section-online-orders.png';
import secondSectionImg from '../../assets/images/landing/second-section.png';

const Landing = () => {
  return (
    <>
      <main className={styles.Landing}>
        <div className={styles.aboveTheFold}>
          <div className={styles.contentWrapper}>
            <Header className={styles.header}/>
            <div className={styles.content}>
              <div className={styles.leftSection}>
                <h1 className={styles.mainTitle}>
                  Vende comida en línea hoy mismo
                </h1>
                <p className={styles.mainDescription}>
                  Adaptate a la nueva realidad vendiendo tus productos gastronómicos en línea.
                  Create una cuenta gratis, sube tus productos y vende en lìnea en minutos. 
                </p>
                <div className={styles.ctaSection}>
                  <a href="https://www.gal-da.com/register" target="_blank" className={styles.cta}>Empieza gratis ahora</a>
                  <small>Sin tarjetas de crédito requeridas</small>
                </div>
                <figure className={styles.clientsLogos}>
                  <ul>
                    <li><img src={client1Logo} /></li>
                    <li><img src={client2Logo} /></li>
                    <li><img src={client3Logo} /></li>
                    <li><img src={client4Logo} /></li>
                  </ul>
                </figure>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.imgWrapper}>
                  <img src={banner} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className={[styles.contentWrapper, styles.contentBridge].join(' ')}>
          <h2>Diseñado para emprendedores gastronómicos</h2>
          <p>
            Creado por emprendedores para emprendedores. Únete a la revolución en 
            herramientas digitales para restaurantes.
          </p>
        </section>

        <section className={styles.feature}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div className={styles.leftSection} >
                <h2 className={styles.featureTitle}>
                  Acepta pedidos en tiempo real con tu menú online
                </h2>
                <p className={styles.featureDescription}>
                  <ol>
                    <li>Crea tu propio menú y llega a más clientes</li>
                    <li>No necesitas descargar una aplicación en el acceso es a través de un navegador web</li>
                    <li>Adaptable a cualquier dispositivo</li>
                    <li>Soporte general</li>
                  </ol>
                </p>
              </div>
              <div className={[styles.rightSection]} >
                <div className={styles.imgWrapper}>
                  <img className={styles.onlineOrdersImg} src={firstSectionImg} alt="Gal-da pedidos en línea" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.feature}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div className={styles.leftSection} >
                <div className={styles.imgWrapper}>
                  <img src={secondSectionImg} alt="Multiples herramientas integradas Gal-da" />
                </div>
              </div>
              <div className={styles.rightSection} >
                <h2 className={styles.featureTitle}>
                  Integrado con sistema gestión y 
                  punto de venta
                </h2>
                <p className={styles.featureDescription}>
                  <ol>
                    <li>Ingresa las imágenes a tus Productos</li>
                    <li>Ingresa tu logo</li>
                    <li>Horarios, ubicación, contacto y redes sociales</li>
                    <li>Minimiza gastos - Reduce costes de imprimir cartas de papel</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.carrouseWrapper}>
          <div className={styles.contentWrapper}>
            <Carrousel className={styles.carrousel} timing={2300}>
              <img src={tabernaDesktop} />
              <img src={tabernaMobile} />
              <img src={cebicheriaDesktop} />
            </Carrousel>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Landing;