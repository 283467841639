import React, { useState } from 'react'
import CategoryList from './CategoryList';
import ProductFilter from './ProductFilter';
import searchIcon from '../../assets/images/searchIcon.svg';
import { connect } from 'react-redux';
import { filterPresentationsCatg, filterPresentationsName } from '../../redux/actions';

const CategoryProductSearch = props => {
  const {
    filterPresentationsName, 
    filterPresentationsCatg, 
    filteredCatgId, 
    filteredName,
    merchantUrlName
  } = props;
  const [filtering, setFiltering] = useState(false);
  const closeFiltering = () => {
    setFiltering(false);
  }

  return (
    !filtering ?    
    <div className="categoriesProductSearch">
      <CategoryList filterCatg={filterPresentationsCatg} filteredCatgId={filteredCatgId} merchantUrlName={merchantUrlName} />
      <img onClick={() => setFiltering(true)} src={searchIcon} alt="search icon on Gal-da menu" />
    </div>
    : <ProductFilter closeFiltering={closeFiltering} filterName={filterPresentationsName} filteredName={filteredName} />
  );
};

const mapDispatchToProps = dispatch => ({
  filterPresentationsName: (filter) => dispatch(filterPresentationsName(filter)),
  filterPresentationsCatg: (filter) => dispatch(filterPresentationsCatg(filter)),
});

const mapStateToProps = state => ({
  filteredCatgId: state.presentations.filterCatgId,
  filteredName: state.presentations.filterName,
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductSearch);
