import {combineReducers} from 'redux';
import cartReducer from './cartReducer';
import checkoutReducer from './checkoutReducer';
import merchantReducer from './merchantReducer';
import modalReducer from './modalReducer';
import orderFlowReducer from './orderFlowReducer';
import presentationsReducer from './presentationsReducer';

const rootReducer = combineReducers({
  modal: modalReducer,
  // cart: cartReducer,
  presentations: presentationsReducer,
  merchant: merchantReducer,
  // checkout: checkoutReducer,
  orderFlow: orderFlowReducer,
});

export default rootReducer;
