import styles from './Header.module.scss';
import logo from '../../../assets/images/logo.svg';
const Header = ({className}) => {
  return (
    <header className={[styles.Header, className].join(' ')}>
      <img className={styles.logo} src={logo} />
      <a href="https://www.gal-da.com/pricing" target="_blank" className={styles.mainCta}>Ver planes</a>
    </header>
  );
}

export default Header;
