import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toggleModal } from '../../redux/actions';
import defImg from '../../assets/images/productImageDef.png';
import api from '../../services/api';
import AddProductModal from './AddProductModal';

const ProductItem = props => {

  const {handleItemClick, productItem, currency} = props;

  return (
    <div className="ProductItem">
      <div className="productPictureWrapper">
        <img src={(productItem.signedImageurl && productItem.signedImageurl !== 'not found' ) ? productItem.signedImageurl : defImg } alt="Merchant product image" />
      </div>
      <div className="productInfo">
        <div className="info">
          <div className="productPresName">
            <span className="nameProd">{productItem.product.name}</span>
            <span className="namePres">{productItem.name}</span>
          </div>
          <span className="price">{`${currency || ''}${parseFloat(productItem.price).toFixed(2)}`}</span>
        </div>
        {/* <button onClick={() => handleItemClick(productItem)} className="add">Agregar</button> */}
      </div>
    </div>
  );
}

ProductItem.defaultProps = {
  currency: '',
};

const ProductList = props => {
  const {toggleModal, filterName, filterCatgId, merchantUrlName, merchant} = props;
  const [productList, setProductList] = useState([]);
  const filterNameRgx = new RegExp(`${filterName}`, 'i');

  const handleAddProductClose = () =>{
    toggleModal(false);
  }

  const handleItemClick = (productItem) => {
    toggleModal(true, <AddProductModal 
                        handleClose={handleAddProductClose} 
                        productItem={productItem}
                      />);
  };

  useEffect(() => {
    api.fetchPresentations(merchantUrlName).then( presentations => {
      setProductList(presentations);
    }); 
  }, []);

  return (
    <div className="ProductList">
      {productList
        .filter(prod => (`${prod.product.name} ${prod.name}`.match(filterNameRgx)) || filterName === ''  )
        .filter(prod => prod.product.product_category_id === filterCatgId || filterCatgId === null)
        .map(prod => <ProductItem 
                        key={prod.id_product_presentation} 
                        handleItemClick={handleItemClick} 
                        productItem={prod}
                        currency={merchant.moneda}
                      /> 
        )}
    </div>
  );
};

const mapStateToProps = state => ({
  filterName: state.presentations.filterName,
  filterCatgId: state.presentations.filterCatgId,
  merchant: state.merchant,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: (show, modal) => dispatch(toggleModal(show, modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
