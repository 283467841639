import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createNewOrderFlow, filterPresentationsCatg, filterPresentationsName, loadMerchantInfo } from '../redux/actions';
import '../assets/styles/restaurantPage.scss';
import api from '../services/api';
import CategoryProductSearch from './restaurant_page/CategoryProductSearch';
import ProductList from './restaurant_page/ProductList';
import Header from './util/Header';
import {useParams, withRouter} from 'react-router-dom';
import Footer from './util/footer/Footer';

const RestaurantPage = props => {
  const [loading, setLoading] = useState(true);
  const {loadMerchantInfo, createNewOrderFlow, filterPresentationsCatg, filterPresentationsName} = props;
  const params = useParams();
  const {merchantUser} = params;
  const merchant = useSelector(state => state.merchant);

  useEffect(() => {    
    api.fetchMerchantInfo(merchantUser).then((merchantInfo) => {
      if(merchantUser !== merchant.empresa_usuario) {
        filterPresentationsCatg(null); 
        filterPresentationsName('');
      }
      loadMerchantInfo(merchantInfo);
      createNewOrderFlow(merchantInfo);
    });
  }, []);

  return (
    <>
    <Header />
    <main className="restaurantPage">
      <h2 className="mainTitle">Productos</h2>
      <CategoryProductSearch merchantUrlName={merchantUser}  />
      <ProductList merchantUrlName={merchantUser} />
    </main>
    <Footer />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  loadMerchantInfo: (info) => dispatch(loadMerchantInfo(info)),
  createNewOrderFlow: (merchant) => dispatch(createNewOrderFlow(merchant)),
  filterPresentationsName: (filter) => dispatch(filterPresentationsName(filter)),
  filterPresentationsCatg: (filter) => dispatch(filterPresentationsCatg(filter)),
});

export default connect(null, mapDispatchToProps)(RestaurantPage);
