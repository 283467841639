import { FILTER_PRESENTATIONS_CATG, FILTER_PRESENTATIONS_NAME } from "../actions";

const initialState = {
  filterName: '',
  filterCatgId: null,
}

const presentationsReducer = (state = initialState, action) => {
  switch(action.type) {
    case FILTER_PRESENTATIONS_NAME: {
      return Object.assign({}, state, {filterName: action.filter});
    }
    case FILTER_PRESENTATIONS_CATG: {
      return Object.assign({}, state, {filterCatgId: action.filter});
    }
    default: return state;
  } 
}

export default presentationsReducer;
