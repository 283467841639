import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addItemCart, clearCart, lowItemQuantityCart, removeItemCart } from '../../redux/actions';
import Backdrop from '../util/Backdrop';
import RightTopCloseButton from '../util/RightTopCloseButton';

const CartItem = props => {
  const {cartItem, reduceItemQuantity, addItemCart, removeItemCart, currency} = props;

  const operateQuantity = (operation) => {
    const operateItem = {...cartItem, quantity: 1};
    if(operation === '-') {
      if(cartItem.quantity <= 1 ) {
        removeItemCart(operateItem.id_product_presentation);
      } else {
        reduceItemQuantity(operateItem);
      }
    }
    else if(operation === '+') {
      addItemCart(operateItem);
    }
  }

  return (
    <div className="CartItem">
      <div className="itemInfo">
        <span className="name">{`${cartItem.product.name} - ${cartItem.presentacion}`}</span>
        <span className="comment">{cartItem.comment}</span>
      </div>
      <div className="quantitySelector">
        <button onClick={() => operateQuantity('-')}>-</button>
        <span className="quantity">{cartItem.quantity} </span>
        <button onClick={() => operateQuantity('+')}>+</button>
      </div>
      <span className="price">{currency} {cartItem.price}</span>
    </div>
  );
}

const CartSidebar = props => {
  const {cart, handleClose, clearCart, reduceItemQuantity, addItemCart, removeItemCart, history, merchant} = props;

  const handleCheckoutClick = () => {
    handleClose();
    history.push(`/${merchant.user}/checkout`);
  }

  return (
    <Backdrop>
      <div className="CartSidebar" >
        <RightTopCloseButton handleClose={handleClose} />
        <div className="cartHeader">
          <h3>Tus pedidos en la canasta</h3>
        </div>
        <div className="cartItemList">
          {cart.items.map(item => (
            <CartItem 
              key={item.id_product_presentation}
              cartItem={item}  
              reduceItemQuantity={reduceItemQuantity} 
              addItemCart={addItemCart}
              removeItemCart={removeItemCart}
              currency={merchant.moneda}
              />
          ))}
        </div>
        <div className="cartFooter">
          <button onClick={clearCart} className="clearCartButton"> Vaciar canasta</button>
          <button className="toCheckoutButton" onClick={handleCheckoutClick} disabled={cart.totalAmount > 0 ? false : true} >
            <span>Ordenar</span> 
            <span>{merchant.moneda}{cart.totalAmount}</span>
          </button>
        </div>
      </div>
    </Backdrop>
  );
}

const mapStateToProps = state => ({
  cart: state.orderFlow.activeFlow.cart,
  merchant: state.merchant,
});

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCart()),
  reduceItemQuantity: (item) => dispatch(lowItemQuantityCart(item)),
  addItemCart: (item) => dispatch(addItemCart(item)),
  removeItemCart: (itemId) => dispatch(removeItemCart(itemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartSidebar);
